@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"












































































































































































$card-info-title-height: 56px
$status-border-status: 100px
$custom-card-header-height: 72px
$custom-card-body-height: 100px

.assessment-card
  display: flex
  flex-direction: column
  justify-content: space-between
  background-image: url("~@/assets/card.svg") !important
  background-position: 0px -24px !important
  background-size: 100% !important

  +mq-s--mf
    margin-bottom: 0

  &:hover
    cursor: pointer
    box-shadow: $shadow-l $color-ink-lighter

  &.--loading
    background-color: $color-ink-lighter !important

  &__header
    position: relative
    display: flex
    align-items: center
    justify-content: space-between
    margin: $size-xxs $size-s
    color: $color-white
    height: auto
    min-height: auto

    +mq-s--mf
      margin: $size-s
      align-items: flex-start
      min-height: $custom-card-header-height

    &__info
      display: flex
      flex-direction: column
      padding: $size-s 0

      +mq-s--mf
        padding: 0

      &__icon
        display: none !important
        width: $size-l
        margin-bottom: $size-xs

        +mq-s--mf
          display: inline-block !important

      .header-info__title ~ .header-info__title
        margin-top: $size-xxs

  &__body
    display: flex
    flex-direction: column
    justify-content: space-between
    background-color: $color-white
    border-radius: 2 * $border-radius-m
    border-top-right-radius:  2 * $border-radius-s
    border-top-left-radius: 0
    padding: $size-s
    min-height: $custom-card-body-height !important
    height: 100%

    &__info

      .info-status
        margin-bottom: $size-s

        &__text
          font-size: $font-size-xs
          background-color: $color-ink-lightest
          padding: $size-xxs $size-xs
          border-radius: $status-border-status

      .info-title
        line-height: $size-m
        margin-bottom: $size-m

        +mq-s--mf
          height: $card-info-title-height

    &__application-range
      display: flex
      align-items: center
      font-size: $font-size-s
      color: $color-ink-light
      margin-top: $size-s

      i
        margin-right: $size-xs

    +mq-s--mf
      min-height: 2 * $custom-card-body-height

  .sas-skeleton-loader ~ .sas-skeleton-loader
      margin-top: $size-xs
