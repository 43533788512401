@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"










































































































































































































































































$dropdown-max-height: 200px

.personal-assessments
  margin-top: $size-m

  +mq-m--mf
    margin-top: $size-xl

  &__filters
    display: flex
    flex-direction: column
    align-items: flex-start

    +mq-m--mf
      flex-direction: row
      align-items: center

    ::v-deep .sas-dropdown,
    ::v-deep .sas-dropdown-menu
      width: 100%

      +mq-s--mf
        width: 320px

    ::v-deep .sas-select
      width: 100%
      flex-direction: column

      +mq-s--mf
        width: 320px

    ::v-deep .sas-dropdown

      .sas-dropdown-menu
        position: fixed
        left: 0
        right: 0
        bottom: 0
        top: auto
        max-height: 100vh
        justify-content: unset
        overflow-y: auto

        &-enter,
        &-leave-to
          transform: translateY(100%)

        +mq-s--mf
          position: absolute
          bottom: auto
          max-height: $dropdown-max-height

          &-enter,
          &-leave-to
            transform: translateY(-10%)

    &__years
      margin-bottom: $size-s

      +mq-m--mf
        margin-bottom: 0
        margin-right: $size-s

  &__cards-listing
    display: grid
    grid-template-columns: 1fr
    grid-gap: $size-s
    margin: $size-xl 0

    +mq-s--mf
      grid-template-columns: repeat(2, 1fr)

    +mq-m--mf
      grid-template-columns: repeat(3, 1fr)
      grid-gap: $size-m

    +mq-l--mf
      grid-template-columns: repeat(4, 1fr)
