@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"











































.empty-state
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  padding: $size-l 0

  +mq-m--mf
   padding-bottom: $size-xxl

  &__image
    max-width: 200px

    +mq-s--mf
      max-width: 320px

  &__warning
    font-size: $font-size-xs
    font-weight: $font-weight-semi-bold
    text-align: center
    margin-top: $size-l

    +mq-xs--mf
      font-size: $font-size-m

    +mq-s--mf
      font-size: $font-size-heading-4-small

    +mq-m--mf
      font-size: $font-size-heading-4

  &__comment
    font-size: $font-size-xs
    text-align: center
    color: $color-ink-light
    margin-top: $size-xs
    max-width: 600px

    +mq-xs--mf
      font-size: $font-size-s

    +mq-s--mf
      font-size: $font-size-m

  &__action
    margin-top: $size-l
